import React, {useEffect} from 'react';
import Headroom from 'react-headroom';
import {Container} from '@material-ui/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.scss';
import Icon from '../icon';
import Links from './Links';
import Intro from './Intro';
import Work from './Work';
import About from './About';

function App() {
  useEffect(() => {
      AOS.init();
  });

  return (
    <div className="App">
      <Headroom>
        <header>
          <h2 id="logo"> <a href="#intro">NS</a> </h2>

          <nav className="inline">
            <ul>
              <li> <a href="#work">Work</a> </li>
              <li> <a href="#about">About</a> </li>
            </ul>
          </nav>
        </header>
      </Headroom>

      <Links/>

      <Container fluid>
        <Intro/>
        <Work/>
        <About/>
      </Container>

      <footer id="footer">
        <div id="mobile-links" className="inline">
          <ul>
            <li>
              <a href="mailto:n.sibal@outlook.com">
                <Icon name="email" width={25}/>
              </a>
            </li>

            <li>
              <a href="https://github.com/nsibal/" target="_blank" rel="noopener noreferrer">
                <Icon name="github" width={25}/>
              </a>
            </li>

            <li>
              <a href="https://linkedin.com/in/nsibal/" target="_blank" rel="noopener noreferrer">
                <Icon name="linkedin" width={25}/>
              </a>
            </li>

            <li>
              <a href="https://twitter.com/sibalnirbhay/" target="_blank" rel="noopener noreferrer">
                <Icon name="twitter" width={25}/>
              </a>
            </li>
            <li>
              <a href="https://1drv.ms/b/s!ApHFShDhRjQflFO0yDC9eL14R6KI"
                target="_blank"
                rel="noopener noreferrer">
                <Icon name="clip" width={25}/>
              </a>
            </li>
          </ul>
        </div>

        <p>&copy; {new Date().getFullYear()} Nirbhay Sibal</p>

      </footer>
    </div>
  );
}

export default App;
