import React from 'react';
import './Links.scss';
import Icon from '../icon';

const Links = () => {
  return (
    <div className="links">
      <ul>
        <li>
          <a href="mailto:n.sibal@outlook.com">
            <Icon className="hvr-grow" name="email" width={25}/>
          </a>
        </li>

        <li>
          <a href="https://github.com/nsibal/" target="_blank" rel="noopener noreferrer">
            <Icon className="hvr-grow" name="github" width={25}/>
          </a>
        </li>

        <li>
          <a href="https://linkedin.com/in/nsibal/" target="_blank" rel="noopener noreferrer">
            <Icon className="hvr-grow" name="linkedin" width={25}/>
          </a>
        </li>

        <li>
          <a href="https://twitter.com/sibalnirbhay/" target="_blank" rel="noopener noreferrer">
            <Icon className="hvr-grow" name="twitter" width={25}/>
          </a>
        </li>

        <li>
          <a href="https://1drv.ms/b/s!ApHFShDhRjQflFO0yDC9eL14R6KI" target="_blank" rel="noopener noreferrer">
            <Icon className="hvr-grow" name="clip" width={25}/>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Links;
