import React from 'react';
import {Grid} from '@material-ui/core';
import './About.scss';
import Portrait from '../img/self-portrait.JPG';

const About = () => {
  return (
    <div id="about" className="viewport-fill vertical-center">
      <Grid container justify="center" alignItems="center">
        <Grid item xs={10} sm={9} data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-delay="150">
          <h1>About</h1>
        </Grid>

        <Grid item xs={10} sm={5} data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-delay="200">
          <p id="bio">
            I'm a software engineer currently based in Seattle, WA, and working on Amazon's Prime Video team.
            I was born and raised in Delhi, IN, and lived there until I finished my undergrad. I moved to
            the US in the Fall of 2018 to pursue MS in Computer Science at UTDallas. Previously, I was a
            Software Engineer Intern at Match.com, where I worked on designing and developing the backend
            infrastructure of Ship - a dating app.
            <br/><br/>
            I'm a huge Apple fanboy, a superhero movie buff, a casual gamer, and a hard-core Paneer lover.
            I also occasionally enjoy photographing anything I like.
          </p>
        </Grid>

        <Grid item xs={0} sm={1}/>

        <Grid item xs={0} sm={3} data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-delay="200">
          <img id="portrait" src={Portrait} alt="Self portrait of Nirbhay Sibal"/>
        </Grid>
      </Grid>
    </div>
  );
}

export default About;
