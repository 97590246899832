import React from 'react';
import {Grid} from '@material-ui/core';
import './Work.scss';
import Amazon from '../assets/companies/amazon.png';
import Match from '../assets/companies/match.png';
import Infosys from '../assets/companies/infosys.png';
import Cris from '../assets/companies/cris.png';

const Work = () => {
  return (
    <div id="work" className="viewport-fill vertical-center">
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item xs={10} sm={9} data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-delay="150">
          <h1>Work</h1>
        </Grid>


        {gridContents("SDE", "Amazon", "Seattle, WA, US", "https://www.amazon.com", "Apr'21 - present", Amazon)}

        {gridContents("SWE Intern", "Match.com", "Dallas, TX, US", "https://www.match.com", "May'19 - Dec'20", Match)}

        {gridContents("Systems Engineer", "Infosys", "Kishangarh, CH, IN", "https://www.infosys.com", "Dec'17 - Aug'18", Infosys)}

        {gridContents("Software Intern", "CRIS, Govt. of India", "Chanakya Puri, DL, IN", "https://www.cris.org.in", "Jul'16 - Aug'16", Cris)}
      </Grid>
    </div>
  );
}

const gridContents = (job, company, location, website, duration, logo) => {
  return (
    <Grid item container xs={10} sm={9} direction="row" justify="center" alignItems="center">
      <Grid item container xs={12} sm={4} direction="column" justify="center" className="job-info" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-delay="200">
        <Grid item xs={12}>
          <h2>{job}</h2>
        </Grid>

        <Grid item xs={12}>
          <h3>
            <a href={website} target="_blank" rel="noopener noreferrer">
              {company}
            </a>
          </h3>
        </Grid>

        <Grid item xs={12}>
          <p>{location}</p>
        </Grid>

        <Grid item xs={12}>
          <p>{duration}</p>
        </Grid>
      </Grid>

      <Grid item xs={0} sm={1}/>

      <Grid item xs={0} sm={3} data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-delay="200">
        <a href={website} target="_blank" rel="noopener noreferrer">
          <img className="company hvr-grow" src={logo} alt={company.concat(" logo")}/>
        </a>
      </Grid>

      <Grid item xs={0} sm={4}/>
    </Grid>
  );
}

export default Work;
