import React from 'react';
import {Grid} from '@material-ui/core';
import './Intro.scss';

const Intro = () => {
  return (
    <div id="intro" className="viewport-fill vertical-center" data-aos="fade-up" data-aos-easing="ease-in-out">
      <Grid container justify="center" alignItems="center">
        <Grid item xs={10} sm={9} data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-delay="150">
          <h1 id="intro-heading">
            Hi, I'm Nirbhay.&nbsp;<span id="pronouns">(he/him)</span>
          </h1>
        </Grid>

        <Grid item xs={10} sm={9} data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-delay="200">
          <div id="intro-content">
            <p>
              I'm a Seattle-based software engineer who studied computer science
              at <a href="https://utdallas.edu/" target="_blank" rel="noopener noreferrer">UTDallas</a>. Currently, I'm
              working on the <a href="https://primevideo.com/" target="_blank" rel="noopener noreferrer">Prime Video</a> team
              at <a href="https://amazon.com/" target="_blank" rel="noopener noreferrer">Amazon</a>.
              <br/><br/>
              I like solving problems that involve complex data structures, software designing, distributed computing, and
              much more.
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Intro;
